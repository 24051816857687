(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/lazy/assets/javascripts/lazy.js') >= 0) return;  svs.modules.push('/components/lb-utils/lazy/assets/javascripts/lazy.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  useEffect,
  useReducer
} = React;
const {
  module: _module
} = svs.core;
const {
  getLogger
} = svs.core.log;
const logger = getLogger('lb-utils:lazy');
const reducer = (state, action) => {
  switch (action.type) {
    case 'retry':
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null
      });
    case 'loaded':
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null,
        Component: action.payload
      });
    case 'error':
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error
      });
    default:
      throw new Error("Unexpected action: ".concat(action.type));
  }
};
const initialState = {
  Component: null,
  error: null
};
function lazy(myComp, cb, options) {
  const pendingLoading = {};
  const tryToLoad = (component, callback) => {
    if (pendingLoading[component]) {
      if (callback && !pendingLoading[component].includes(callback)) {
        pendingLoading[component].push(callback);
      }
      return;
    }
    const onLoaded = function () {
      for (const cb of pendingLoading[component]) {
        cb(...arguments);
      }
      delete pendingLoading[component];
    };
    pendingLoading[component] = [];
    if (callback) {
      pendingLoading[component].push(callback);
    }
    if (component.startsWith('/cl/')) {
      _module.load(component, undefined, onLoaded);
    } else {
      _module.loadModuleComponent(component, onLoaded);
    }
  };
  const LazyComponent = props => {

    let cachedComp;
    try {
      cachedComp = cb();
    } catch (_unused) {
      tryToLoad(myComp);
    }
    const initial = _objectSpread(_objectSpread({}, initialState), {}, {
      Component: cachedComp
    });
    const [state, dispatch] = useReducer(reducer, initial);
    useEffect(() => {
      let wasCancelled = false;
      if (!state.error && !state.Component) {
        const loadCallback = error => {
          if (!wasCancelled) {
            if (error) {
              logger.warn("Failed to fetch component: ".concat(myComp, " status: ").concat(error.status, " msg: ").concat(error.statusMessage));
              dispatch({
                type: 'error',
                error: {
                  code: error.status,
                  message: error.statusMessage
                }
              });
            } else {
              dispatch({
                type: 'loaded',
                payload: cb()
              });
            }
          }
        };
        tryToLoad(myComp, loadCallback);
      }
      return () => {
        wasCancelled = true;
      };
    }, [state.Component, state.error]);
    const onRetry = () => {
      dispatch({
        type: 'retry'
      });
    };
    if (!state.Component) {
      if (options !== null && options !== void 0 && options.error && state.error) {
        const Error = options.error;
        return React.createElement(Error, {
          componentProps: props,
          error: state.error,
          onRetry: onRetry
        });
      }
      if (options !== null && options !== void 0 && options.fallback) {
        const Fallback = options.fallback;
        return React.createElement(Fallback, {
          componentProps: props
        });
      }
      return null;
    }

    return React.createElement(state.Component, props);
  };
  return LazyComponent;
}
setGlobal('svs.components.lbUtils.lazy.lazy', lazy);

 })(window);